

export const getMenuCatalogo = (state)=>{
    return state.menuCatalogo
}

export const getMenuAplicates = (state) =>{
    return state.menuAplicates
}

export const getisOpenfiltersWeb = (state) =>{
    return state.isOpenfiltersWeb
}

export const getCatalogoSerieProductoSelecte = (state) =>{
    return state.catalogoSerieProductoSelecte
}

export const getAreaSelected=(state)=>{
    return state.areaSelected
}

export const getAplicationSeletec=(state)=>{
    return state.aplicationSelected
}

export const getProductsAplicatesInAreas=(state)=>{
    return state.productsAplicatesInAreas
}

export const getisLoadingProductToAplicate=(state)=>{
    return state.isLoadingProductToAplicate
}