<template>
  <div class="w-screen h-screen">
      <div class='h-full w-full  flex justify-center items-center'>
          <div class="text-center">
              <p class="text-black text-9xl" >404...</p>
              <p class="text-black text-2xl">Now You See It...</p>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>